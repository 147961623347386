import PropTypes from 'prop-types'
import { ADMIN } from './constants'
import React from 'react'
import { observer } from 'mobx-react'
import { useStore } from './stores'
import {
	Home,
	UserManagement,
	LoginScreen,
	Dashboard,
	StoreDetails,
	EditStore,
	PasswordReset,
	UpdatePassword,
	UserInviteScreen,
	OTAScreen,
} from './screens'
import { Navigate, createBrowserRouter } from 'react-router-dom'

const LoginRoute = observer(() => {
	const { authStore } = useStore()
	const authenticated = authStore.authenticated
	if (authenticated) return <Navigate to='/home' />
	return <LoginScreen />
})

const PrivateRoute = observer(({ element, role }) => {
	const { authStore } = useStore()
	const authenticated = authStore.authenticated
	const userRoles = authStore.userRoles

	if (role && !userRoles.includes(role)) return <Navigate to='/home' />

	return authenticated ? element : <Navigate to='/login' />
})

const router = createBrowserRouter([
	{
		path: '/',
		element: <PrivateRoute element={<Home />} />,
	},
	{
		path: '/reset-password/:token',
		element: <UpdatePassword />,
	},
	{
		path: '/accept-invite/:token',
		element: <UserInviteScreen />,
	},
	{
		path: '/home',
		element: <PrivateRoute element={<Home />} />,
	},
	{
		path: '/login',
		element: <LoginRoute />,
	},
	{
		path: '/reset-password',
		element: <PasswordReset />,
	},
	{
		path: '/dashboard',
		element: <PrivateRoute element={<Dashboard />} />,
	},
	{
		path: '/store/:storeID',
		element: <PrivateRoute element={<StoreDetails />} />,
	},
	{
		path: '/store',
		element: <PrivateRoute element={<StoreDetails />} />,
	},
	{
		path: '/editstore/:storeID',
		element: <PrivateRoute element={<EditStore />} />,
	},
	{
		path: '/editstore',
		element: <PrivateRoute element={<EditStore />} />,
	},
	{
		path: '/user-management',
		element: <PrivateRoute element={<UserManagement />} role={ADMIN} />,
	},
	{
		path: '/ota-management',
		element: <PrivateRoute element={<OTAScreen />} role={ADMIN} />,
	},
])

PrivateRoute.propTypes = {
	element: PropTypes.element,
}

export default router
