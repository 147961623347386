import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Divider, Flex, Input, Modal, Checkbox, Button, Alert, notification, Typography } from 'antd'

import WarningModal from '../WarningModal'
import { API } from '../../../../App'
import { StarFilled } from '@ant-design/icons'
import Title from '../../Title'
import { observer } from 'mobx-react'
import { useStore } from '../../../../stores'

const CheckboxGroup = Checkbox.Group

const CreateProcessModal = observer(({ isOpen, onCancel, versions }) => {
	const [error, setError] = useState()
	const [customers, setCustomers] = useState([])
	const [processName, setProcessName] = useState()
	const [isWarningModalOpen, setIsWarningModalOpen] = useState()

	const { versionStore } = useStore()

	useEffect(() => {
		versionStore.getLastVersion()
	}, [])

	useEffect(() => {
		const fetchCustomers = async () => {
			const response = await API.getStoreNames()

			if (response && response.ok && response.data) {
				response.data.splice(0, 1)
				setCustomers(response.data)
			}
		}

		fetchCustomers()
	}, [])

	// customers
	const [checkedList, setCheckedList] = useState([])

	const checkAll = customers.length === checkedList.length

	useEffect(() => {
		if (processName?.length === 0) {
			setError({ status: 'error', placeholder: 'Process name field could not be empty' })
		} else if (checkedList.length < 1) {
			setError({ status: 'error', placeholder: 'Select at least one customer' })
		} else {
			setError()
		}
	}, [processName, checkedList.length])

	const handleWarningModalClose = () => setIsWarningModalOpen(false)

	const onChange = (list) => {
		setCheckedList(list)
	}

	const onCheckAllChange = () => {
		if (checkedList.length === customers.length) {
			setCheckedList([])
		} else {
			setCheckedList(customers)
		}
	}

	const openNotificationWithIcon = (type, message) => {
		notification[type]({
			style: {
				width: '350px',
				minHeight: '50px',
			},
			message: '',
			description: message,
		})
	}

	const [loading, setLoading] = useState(false)
	// Finish step
	const onWarningModalSubmit = async () => {
		setLoading(true)
		try {
			const res = await API.createProcess({
				versionName: versionStore?.versionData?.name,
				databaseVersion: versionStore?.versionData?.databaseVersion,
				firmwareVersion: versionStore?.versionData?.firmwareVersion,
				processName,
				customers: checkedList.map((customer) => ({ name: customer })),
			})

			setLoading(false)
			if (!res.ok) {
				handleWarningModalClose()
				setError({ status: 'error', placeholder: res.data.error.message })
				openNotificationWithIcon('error', res.data.error.message)

				return
			}
		} catch (err) {
			setLoading(false)
			return
		}

		setLoading(false)
		onCancel()
	}

	const modalStyles = {
		content: {
			width: '80vw',
			maxWidth: '900px',
		},
	}

	return (
		<Modal title={'Process creation'} open={isOpen} onCancel={onCancel} footer={[]} styles={modalStyles}>
			<Flex gap={'middle'} vertical justify='flex-start' align='flex-start'>
				<Flex gap='large' style={{ width: '100%' }} vertical>
					<Flex gap='small' vertical style={{ width: '320px' }} justify={'flex-start'} align='flex-start'>
						<Title title={'Process Name'} asterisk />

						<Input value={processName} onChange={(v) => setProcessName(v.target.value)} />
					</Flex>
					<Flex gap='small' vertical style={{ width: '320px' }} justify={'flex-start'} align='flex-start'>
						<Title title={'Firmware Version'} />
						<Input disabled style={{ color: 'black' }} value={versionStore?.versionData?.firmwareVersion || `N/A`} />
					</Flex>
					<Flex gap='small' vertical style={{ width: '320px' }} justify={'flex-start'} align='flex-start'>
						<span style={{ width: 'fit-content', textWrap: 'nowrap' }}>Database Version</span>
						<Input disabled style={{ color: 'black' }} value={versionStore?.versionData?.databaseVersion || `N/A`} />
					</Flex>
					{error && <Alert style={{ width: 'fit-content' }} message={error.placeholder} type='error' />}
				</Flex>

				<Divider style={{ margin: 0 }} dashed />

				<>
					<Flex justify='space-between' style={{ width: '100%' }}>
						<Typography.Text level={4} style={{ position: 'relative' }}>
							Customers
							<StarFilled style={{ position: 'absolute', top: 0, fontSize: '5px', color: 'red' }} />
						</Typography.Text>
					</Flex>

					<Divider style={{ margin: 0 }} dashed />

					<Flex justify='flex-end' style={{ width: '100%', padding: '0 20px 20px 0' }}>
						<Button onClick={() => onCheckAllChange()} type={checkAll ? 'primary' : 'default'}>
							{checkedList.length === customers.length ? 'Deselect all' : 'Select all'}
						</Button>
					</Flex>

					<CheckboxGroup
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							alignItems: 'flex-start',
							width: '100%',
							gap: '10px',
						}}
						value={checkedList}
						onChange={onChange}
					>
						{customers?.map((v) => (
							<Checkbox key={v} value={v} style={{ width: '30%', overflow: 'hidden' }}>
								{v}
							</Checkbox>
						))}
					</CheckboxGroup>
				</>

				<WarningModal
					{...{
						cancelButtonText: 'No',
						submitButtonText: 'Yes',
						onSubmit: onWarningModalSubmit,
						title: `Are you sure you want to create a new process?`,
					}}
					loading={loading}
					isOpen={isWarningModalOpen}
					onCancel={handleWarningModalClose}
				/>

				<Flex gap='middle' style={{ width: '100%' }} justify={'flex-end'} align='center'>
					<Button onClick={onCancel} type='primary' danger>
						Cancel
					</Button>
					<Button
						onClick={() => setIsWarningModalOpen(true)}
						disabled={!processName || checkedList < 1 || error}
						type='primary'
					>
						Create
					</Button>
				</Flex>
			</Flex>
		</Modal>
	)
})

CreateProcessModal.propTypes = {
	isOpen: PropTypes.bool,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	versions: PropTypes.object,
}

export default CreateProcessModal
