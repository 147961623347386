import { API } from '../App'
import { makeObservable, observable, action } from 'mobx'

class DataStore {
	users = []
	products = []

	processes = []
	processFilters = {}
	selectedProcessId = null

	inProgress = false
	error = null

	constructor() {
		makeObservable(this, {
			users: observable,
			products: observable,
			processes: observable,
			inProgress: observable,
			processFilters: observable,
			selectedProcessId: observable,
			error: observable,
			getUsers: action,
			getProduct: action,
		})
	}

	async callAPI(method, data = {}) {
		this.inProgress = true
		this.error = null

		const response = await method(data)
		if (response.ok) {
			return response.data
		} else {
			this.error = response.data.error
		}
		this.inProgress = false
	}

	async getUsers() {
		const response = await this.callAPI(API.getUsers)
		this.users = response
	}

	async getProduct() {
		const response = await this.callAPI(API.getProducts)
		this.products = response
	}

	async setProcessFilters(filters) {
		this.processFilters = filters
	}

	async getProcesses() {
		const response = await this.callAPI(API.getProcesses, this.processFilters)
		this.processes = response
	}

	async setSelectedProcessId(id) {
		this.selectedProcessId = id
	}
}

export default DataStore
