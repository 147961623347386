import { Card, Flex, Typography } from 'antd'
import React from 'react'

import { ScreenLayout, OTAScreenHeader, ProcessTable, DetailsTabs } from '../components'
import { observer } from 'mobx-react'
import { useStore } from '../stores'

const OTAScreen = observer(() => {
	const { dataStore } = useStore()

	return (
		<ScreenLayout>
			<Card
				title={<Typography.Title level={4}>Firmware and Database Update Management</Typography.Title>}
				style={{
					width: '85vw',
					maxWidth: '1200px',
					margin: 'auto',
					marginTop: 40,
					fontSize: 'large',
				}}
			>
				<Flex vertical wrap='wrap'>
					<OTAScreenHeader />

					<ProcessTable />

					{dataStore.selectedProcessId && <DetailsTabs selectedProcessId={dataStore.selectedProcessId} />}
				</Flex>
			</Card>
		</ScreenLayout>
	)
})

export default OTAScreen
