import { API } from '../App'
import { makeObservable, observable, action } from 'mobx'

class ProcessDetailsStore {
	processDetails = null
	inProgress = false
	error = null

	constructor() {
		makeObservable(this, {
			processDetails: observable,

			inProgress: observable,
			error: observable,
		})
	}

	async callAPI(method, data = {}) {
		this.inProgress = true
		this.error = null

		const response = await method(data)
		if (response.ok) {
			this.inProgress = false

			return response.data
		} else {
			this.error = response.data.error
		}

		this.inProgress = false
		console.log('false', this.inProgress)
	}

	async getDetails(id) {
		const response = await this.callAPI(API.getProcessDetails, id)
		this.processDetails = response
	}

	async clearDetails() {
		this.processDetails = null
	}
}

export default ProcessDetailsStore
