import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'

const StatusTag = ({ status, statusColorMap }) => {
	return (
		<Tag color={statusColorMap[status.toUpperCase()]} key={status}>
			{status}
		</Tag>
	)
}

StatusTag.propTypes = {
	status: PropTypes.string.isRequired,
	statusColorMap: PropTypes.object,
}

export default StatusTag
