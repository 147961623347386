import React from 'react'
import PropTypes from 'prop-types'
import { StarFilled } from '@ant-design/icons'

const Title = ({ asterisk, title }) => (
	<span style={{ width: 'fit-content', textWrap: 'nowrap', position: 'relative' }}>
		{title}
		{asterisk && <StarFilled style={{ position: 'absolute', top: 0, fontSize: '5px', color: 'red' }} />}
	</span>
)

Title.propTypes = {
	asterisk: PropTypes.bool,
	title: PropTypes.string.isRequired,
}

export default Title
