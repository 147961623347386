import React from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from 'antd'

const TooltipIconButton = ({ icon, onClick, text }) => {
	return (
		<Tooltip title={text}>
			<Button onClick={onClick} icon={icon} />
		</Tooltip>
	)
}

TooltipIconButton.propTypes = {
	icon: PropTypes.string,
	onClick: PropTypes.func,
	text: PropTypes.string,
}

export default TooltipIconButton
