import { Space, Checkbox, Select, Card, Button, Layout, DatePicker, Spin } from "antd"
import { useStore } from "../stores"
import PropTypes from "prop-types"
import moment from "moment"
import dayjs from "dayjs"

import React, { useEffect, useState } from "react"
import { ScreenLayout, HierarchySelector, SearchBar } from "../components"
import { API } from "../App"
import { CSVLink } from "react-csv"
import "../App.css"
import Title from "antd/es/skeleton/Title"
import { Text } from "recharts"
import { useNavigate } from "react-router"
import { getDivideResult } from "../utils/calculations"
import { presets } from "../utils/dateRangePreset"

const { Option } = Select

const ReportFileName = {
	Dispenser: "Dispenser_Information_",
	Flavor: "Flavor_Levels_",
	Customer: "Customer_Usage_",
}

// Function to calculate the difference in days between two dates
const daysDifference = (date1, date2) => {
	try {
		if (!date1 || !date2) return 0
		date1 = new Date(date1)
		date2 = new Date(date2)
		const diffTime = Math.abs(date2 - date1)
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
		return diffDays + 1
	} catch (error) {
		return 0
	}
}

const getEarliestCondensedLogDate = (condensedLog) => {
	if (!condensedLog) return condensedLog
	let earliestDate = null

	for (const dateStr in condensedLog) {
		const currentDate = new Date(dateStr)

		if (currentDate.getTime() < new Date("2015-01-01").getTime()) {
			continue
		}

		if (!earliestDate || currentDate < earliestDate) {
			earliestDate = currentDate
		}
	}

	return earliestDate
}

const getLastestCondensedLogDate = (condensedLog) => {
	if (!condensedLog) return condensedLog
	let lastDate = null

	for (const dateStr in condensedLog) {
		const currentDate = new Date(dateStr)

		if (!lastDate || currentDate > lastDate) {
			lastDate = currentDate
		}
	}

	return lastDate
}

const Home = () => {
	const navigate = useNavigate()
	const { authStore } = useStore()
	const [isReportLoading, setIsReportLoading] = useState(false)
	const [filteredStores, setFilteredStores] = useState([])
	const [, setStoreNames] = useState([])
	const [hierarchyFilter, setHierarchyFilter] = useState()
	const [reportType, setReportType] = useState()
	const [addLocationData, setAddLocationData] = useState(false)
	const [wifiConnectedOnly, setWifiConnectedOnly] = useState(true)
	const [csvData, setCSVData] = useState({ rows: [], headers: [] })
	const [dateRange, setDateRange] = useState([null, null])
	const isAdmin = authStore.isAdmin

	const getFilename = (datesRange) => {
		if (datesRange && datesRange[0] && datesRange[1]) {
			const startDate = datesRange[0].format("MM-DD-YYYY")
			const lastDate = datesRange[1].format("MM-DD-YYYY")
			return `${ReportFileName[reportType]}${startDate}_to_${lastDate}.csv`
		}
		return `${ReportFileName[reportType]}${moment().format("MM-DD-YYYY")}.csv`
	}

	useEffect(() => {
		API.getStoreNames().then((res) => {
			setStoreNames(res.data.filter((d) => d !== null && d !== ""))
		})
	}, [])

	const generateReport = async () => {
		setIsReportLoading(true)
		try {
			let startDate = null
			let endDate = null
			if (dateRange && dateRange[0] && dateRange[1]) {
				startDate = dateRange[0].format("MM-DD-YYYY")
				endDate = dateRange[1].format("MM-DD-YYYY")
			}

			setCSVData({ rows: [], headers: [] })
			let filters = { ...hierarchyFilter }

			if (wifiConnectedOnly) {
				filters.where = {
					...filters.where,
					$and: [
						{ dispenser_snapshot: { $exists: true } },
						{ "dispenser_snapshot.dispenser_mac_address": { $ne: "" } },
						{ "dispenser_snapshot.dispenser_mac_address": { $ne: null } },
					],
				}
			}

			const res = await API.streamStoresData({ ...filters })

			let headers = [
				{ label: "Customer", key: "hierarchyLevel1" },
				{ label: "Location ID", key: "location_id" },
				{ label: "Customer Number", key: "customer_number" },
				{ label: "Store Number", key: "store_number" },
			]

			if (addLocationData) {
				headers.push(
					...[
						{ label: "Address", key: "address1" },
						{ label: "Address 2", key: "address2" },
						{ label: "City", key: "city" },
						{ label: "State", key: "state" },
						{ label: "Zip", key: "zip" },
					],
				)
			}

			let rows = []
			if (reportType === "Dispenser") {
				headers.push(
					{ label: "Serial #", key: "dispenser_serial_number" },
					{ label: "Type", key: "dispenser_type" },
					{ label: "Model #", key: "dispenser_model_number" },
					{ label: "MAC Address", key: "dispenser_mac_address" },
					{ label: "Firmware", key: "dispenser_firmware" },
					{ label: "Database", key: "dispenser_database" },
					{ label: "Last Transmission Date", key: "last_transmission" },
					{ label: "Last Transmission Time", key: "last_transmission_time" },
					{ label: "Last Sanitization Prompt Date", key: "last_sanitization_prompt" },
					{ label: "Last Sanitization Prompt Time", key: "last_sanitization_prompt_time" },
					{ label: "Last Sanitization Date", key: "last_sanitization_complete" },
					{ label: "Last Sanitization Time", key: "last_sanitization_complete_time" },
					{ label: "Last Water Calibration Date", key: "last_water_calibration" },
					{ label: "Last Water Calibration Time", key: "last_water_calibration_time" },
				)
				rows = res.data.map((d) => {
					let rowData = {
						hierarchyLevel1: d.hierarchyLevel1,
						portal_number: d.portal_number,
						location_id: d.location_id,
						store_number: d.store_number,
						customer_number: d.customer_number,
					}
					if (d.dispenser_snapshot) {
						rowData.dispenser_type = d.dispenser_snapshot?.dispenser_type
						rowData.dispenser_model_number = d.dispenser_snapshot?.dispenser_model_number
						rowData.dispenser_serial_number = d.dispenser_snapshot?.dispenser_serial_number
						rowData.dispenser_mac_address = d.dispenser_snapshot?.dispenser_mac_address
						rowData.dispenser_firmware = d.dispenser_snapshot?.dispenser_firmware
						rowData.dispenser_database = d.dispenser_snapshot?.dispenser_database
						rowData.last_transmission = d.dispenser_snapshot.last_transmission
							? moment.utc(d.dispenser_snapshot?.last_transmission).format("MM/DD/YYYY")
							: null
						rowData.last_sanitization_prompt = d.dispenser_snapshot.last_sanitization_prompt
							? moment.utc(d.dispenser_snapshot?.last_sanitization_prompt).format("MM/DD/YYYY")
							: null
						rowData.last_sanitization_complete = d.dispenser_snapshot.last_sanitization_complete
							? moment.utc(d.dispenser_snapshot?.last_sanitization_complete).format("MM/DD/YYYY")
							: null
						rowData.last_water_calibration = d.dispenser_snapshot.last_water_calibration
							? moment.utc(d.dispenser_snapshot?.last_water_calibration).format("MM/DD/YYYY")
							: null
						rowData.last_transmission_time = d.dispenser_snapshot.last_transmission
							? moment.utc(d.dispenser_snapshot?.last_transmission).format("HH:mm:SS")
							: null
						rowData.last_sanitization_prompt_time = d.dispenser_snapshot.last_sanitization_prompt
							? moment.utc(d.dispenser_snapshot?.last_sanitization_prompt).format("HH:mm:SS")
							: null
						rowData.last_sanitization_complete_time = d.dispenser_snapshot.last_sanitization_complete
							? moment.utc(d.dispenser_snapshot?.last_sanitization_complete).format("HH:mm:SS")
							: null
						rowData.last_water_calibration_time = d.dispenser_snapshot.last_water_calibration
							? moment.utc(d.dispenser_snapshot?.last_water_calibration).format("HH:mm:SS")
							: null
					}
					if (addLocationData) {
						rowData.address1 = d.address1
						rowData.address2 = d.address2
						rowData.city = d.city
						rowData.state = d.state
						rowData.zip = d.zip
					}
					return rowData
				})
			} else if (reportType === "Flavor") {
				headers.push(
					{ label: "Flavor", key: "flavor" },
					{ label: "Flavor Volume %", key: "level" },
					{ label: "Exp", key: "exp_date" },
					{ label: "Lot Number", key: "lot_num" },
				)
				rows = res.data
					.map((store) => {
						let storeData = {
							hierarchyLevel1: store.hierarchyLevel1,
							portal_number: store.portal_number,
							location_id: store.location_id,
							store_number: store.store_number,
							customer_number: store.customer_number,
						}
						if (addLocationData) {
							storeData.address1 = store.address1
							storeData.address2 = store.address2
							storeData.city = store.city
							storeData.state = store.state
							storeData.zip = store.zip
						}

						if (!store.dispenser_snapshot?.flavors) {
							return { ...storeData }
						} else {
							return store.dispenser_snapshot?.flavors?.map((flavorEl) => {
								const { flavor, level, lot_num, exp_date } = flavorEl
								return { ...storeData, flavor, level, exp_date, lot_num }
							})
						}
					})
					.flat()
			} else if (reportType === "Customer") {
				headers.push(
					{ label: "Customer Hierarchy Label 2", key: "hierarchy_level_2" },
					{ label: "Customer Hierarchy Label 3", key: "hierarchy_level_3" },
					{ label: "Customer Hierarchy Label 4", key: "hierarchy_level_4" },
					{ label: "Recon", key: "recon" },
					{ label: "Recon + Flavor", key: "recon_flavor" },
					{ label: "Flavor", key: "flavor" },
					{ label: "Opps", key: "opportunities" },
					{ label: "Opps/Day/Store", key: "opportunities_day_store" },
					{ label: "Flavorings", key: "flavorings" },
					{ label: "Flavorings/Day/Store", key: "flavorings_day_store" },
					{ label: "Flavoring %", key: "flavorings_performance" },
					{ label: "First Data Date", key: "first_data_date" },
					{ label: "Connected on Date", key: "first_log" },
					{ label: "Last Logged Data Date ", key: "last_log" },
					{ label: "Days of Data", key: "days_of_data" },
					{ label: "Last Transmission Date", key: "last_transmission" },
					{ label: "Start Date", key: "start_date" },
					{ label: "End Date", key: "end_date" },
				)
				rows = res.data.map((store) => {
					let rowData = {
						hierarchyLevel1: store.hierarchyLevel1,
						portal_number: store.portal_number,
						location_id: store.location_id,
						store_number: store.store_number,
						customer_number: store.customer_number,
					}

					const hierarchyLevel1 = store.hierarchyLevel1
					const hierarchyLevel2 = store.hierarchyLevel2
					const hierarchyLevel3 = store.hierarchyLevel3
					const hierarchyLevel4 = store.hierarchyLevel4

					let lastTransmission = store?.dispenser_snapshot?.last_transmission

					if (!lastTransmission) {
						const lastLog = getLastestCondensedLogDate(store?.condensed_log?.day)
						if (!lastLog) {
							return undefined
						} else {
							lastTransmission = lastLog
						}
					}

					if (lastTransmission && startDate && dayjs(lastTransmission).isBefore(dayjs(startDate))) {
						return undefined
					}

					const storeConnectedOn = store?.connectedOn

					let connectedDate = storeConnectedOn ? dayjs(storeConnectedOn).format("MM/DD/YYYY") : null

					const earliestLogDate = getEarliestCondensedLogDate(store?.condensed_log?.day)
					if (!connectedDate && earliestLogDate) {
						connectedDate = earliestLogDate ? dayjs(earliestLogDate).format("MM/DD/YYYY") : null
					}

					let actualFirstDataDate = connectedDate

					if (connectedDate && earliestLogDate && dayjs(earliestLogDate).isBefore(dayjs(connectedDate))) {
						actualFirstDataDate = earliestLogDate ? dayjs(earliestLogDate).format("MM/DD/YYYY") : null
					}

					if (!connectedDate) {
						return undefined
					}

					if (connectedDate && endDate && dayjs(connectedDate).isAfter(dayjs(endDate))) {
						return undefined
					}

					let totalOpportunities = 0
					let totalFlavorings = 0
					let totalReconCount = 0
					let totalFlavorCount = 0
					let totalReconFlavorCount = 0
					let totalReportedDays = 0
					let totalReportedDaysWithEmpty = 0
					let opportunitiesAvg = 0
					let flavoringsAvg = 0
					let performanceFlavorings = 0

					let firstLog = ""
					let lastLog = ""

					const startDateTime = startDate ? new Date(startDate).getTime() : null
					const endDateTime = endDate ? new Date(endDate).getTime() : null

					// Calculate total statistic
					for (const dayLog in store?.condensed_log?.day) {
						if (!dayLog) continue

						const dayLogValue = store.condensed_log.day[dayLog]
						if (!dayLogValue) continue

						const logDate = new Date(dayLog).getTime()
						if (startDateTime && endDateTime && (logDate < startDateTime || logDate > endDateTime)) {
							continue
						}

						const dayOpportunities = +dayLogValue?.opportunities || 0
						const dayFlavorings = +dayLogValue?.flavorings || 0
						const flavorDayStore = +dayLogValue.flavor_dispense || 0
						const reconFlavorDayStore = +dayLogValue.recons_and_flavor_dispense || 0
						const reconDayStore = +dayLogValue.recons_dispense || 0
						const manualFlavDispense = +dayLogValue.manual_flavor_dispense || 0
						const manualWaterDispense = +dayLogValue.manual_water_dispense || 0

						totalOpportunities += dayOpportunities
						totalFlavorings += dayFlavorings
						totalReconCount += reconDayStore
						totalFlavorCount += flavorDayStore
						totalReconFlavorCount += reconFlavorDayStore
						totalReportedDays = dayOpportunities ? totalReportedDays + 1 : totalReportedDays
						totalReportedDaysWithEmpty += 1

						// Set up last and first log date
						if (manualFlavDispense || manualWaterDispense || reconFlavorDayStore || flavorDayStore || reconDayStore) {
							const currentLastLogTime = lastLog ? new Date(lastLog).getTime() : null
							// const currentFirstLogTime = firstLog ? new Date(firstLog).getTime() : null
							const nextLogTime = dayLog ? new Date(dayLog).getTime() : null

							// if ((currentFirstLogTime && nextLogTime < currentFirstLogTime) || !currentFirstLogTime) {
							// 	firstLog = dayLog
							// }

							if ((currentLastLogTime && nextLogTime > currentLastLogTime) || !currentLastLogTime) {
								lastLog = dayLog
							}
						}
					}

					// Calcuate Average Statistic
					try {
						opportunitiesAvg = getDivideResult(totalOpportunities, totalReportedDays).toFixed(1)
						flavoringsAvg = getDivideResult(totalFlavorings, totalReportedDays).toFixed(1)
						performanceFlavorings = +(getDivideResult(totalFlavorings, totalOpportunities) * 100).toFixed(2)
					} catch (error) {
						// Skip on error
					}

					// Calculate amount of days connected
					// let daysConnected = 0
					// if (connectedDate || startDate || firstLog) {
					// 	const isConnectedDateBeforeStartDate =
					// 		startDate && connectedDate ? new Date(connectedDate).getTime() < new Date(startDate).getTime() : false
					// 	const firstDate = startDate && isConnectedDateBeforeStartDate ? startDate : connectedDate || firstLog

					// 	const lastDate = lastLog || endDate || new Date()

					// 	daysConnected = daysDifference(firstDate, lastDate)
					// }

					rowData.hierarchy_level_2 = hierarchyLevel2
					rowData.hierarchy_level_3 = hierarchyLevel3
					rowData.hierarchy_level_4 = hierarchyLevel4
					rowData.recon = totalReconCount
					rowData.recon_flavor = totalReconFlavorCount
					rowData.flavor = totalFlavorCount
					rowData.opportunities = totalOpportunities
					rowData.flavorings = totalFlavorings
					rowData.flavorings_performance = performanceFlavorings
					rowData.first_data_date = actualFirstDataDate ? moment(actualFirstDataDate).format("MM/DD/YYYY") : null
					rowData.first_log = connectedDate ? moment(connectedDate).format("MM/DD/YYYY") : null
					rowData.last_log = lastLog ? moment(lastLog).format("MM/DD/YYYY") : null
					rowData.last_transmission = lastTransmission ? moment(lastTransmission).format("MM/DD/YYYY") : null
					rowData.days_of_data = totalReportedDaysWithEmpty
					rowData.opportunities_day_store = getDivideResult(totalOpportunities, rowData.days_of_data).toFixed(1)
					rowData.flavorings_day_store = getDivideResult(totalFlavorings, rowData.days_of_data).toFixed(1)
					rowData.start_date = startDate || ""
					rowData.end_date = endDate || ""

					if (addLocationData) {
						rowData.address1 = store.address1
						rowData.address2 = store.address2
						rowData.city = store.city
						rowData.state = store.state
						rowData.zip = store.zip
					}
					return rowData
				})
			}

			const filtredRowsResult = rows.filter((row) => row)

			setIsReportLoading(false)

			setCSVData({ rows: filtredRowsResult, headers })
		} catch (error) {
			console.error("Failed to generate report:", error)
			setIsReportLoading(false)
		}
	}

	return (
		<ScreenLayout>
			<Layout style={{ margin: 20 }}>
				<Card style={{ width: 1200, margin: "20px auto" }}>
					<Title level={4}>Flavoring Program Analytics</Title>
					<SearchBar />
					{isAdmin && (
						<Button style={{ float: "right", marginTop: 5 }} onClick={() => navigate(`/editstore`)}>
							Add New Store
						</Button>
					)}
				</Card>

				<Card style={{ width: 1200, margin: "20px auto" }}>
					<Title level={4}>Reports</Title>
					<Text>Select Customer</Text>
					<br />
					<HierarchySelector
						hierarchyLevel={0}
						filteredStores={filteredStores}
						onSetFilteredStores={setFilteredStores}
						onSetHierarchyFilter={setHierarchyFilter}
						blockNavigation
					/>
					<br />
					<br />
					<Text>Report Type</Text>
					<br />
					<Select placeholder='Select' onChange={(val) => setReportType(val)} style={{ width: 200, marginLeft: "9px" }}>
						<Option value='Dispenser'>Dispenser Information</Option>
						<Option value='Flavor'>Flavor Levels</Option>
						<Option value='Customer'>Customer Usage</Option>
					</Select>
					<br />
					<br />
					{reportType === "Customer" && (
						<>
							<Text>Report Date Range</Text>
							<br />
							<div style={{ marginLeft: "9px" }}>
								<DatePicker.RangePicker
									presets={presets}
									value={dateRange}
									format='MM/DD/YYYY'
									onChange={(val) => setDateRange(val)}
									placeholder={["Start Date", "Last Date"]}
								/>

								<br />
								<br />
							</div>
						</>
					)}
					<Checkbox onChange={(e) => setAddLocationData(e.target.checked)}>Include Store Address Data</Checkbox>
					<br />
					<Checkbox defaultChecked={true} onChange={(e) => setWifiConnectedOnly(e.target.checked)}>
						WiFi Connected Only
					</Checkbox>
					<br />
					<br />
					<Space>
						<Button onClick={generateReport} disabled={isReportLoading}>
							{isReportLoading ? <Spin /> : "Generate Report"}
						</Button>

						{csvData.rows.length > 0 && (
							<CSVLink data={csvData.rows} headers={csvData.headers} filename={getFilename(dateRange)}>
								<Button>Download Report</Button>
							</CSVLink>
						)}
					</Space>
				</Card>
			</Layout>
		</ScreenLayout>
	)
}

Home.propTypes = {
	history: PropTypes.object,
}

export default Home

function calculateDaysOfData(data) {
	let { last_transmission, first_data_date, startDate, lastDate } = data

	last_transmission = last_transmission ? moment(last_transmission, "MM/DD/YYYY") : moment(new Date(), "MM/DD/YYYY")
	first_data_date = first_data_date ? moment(first_data_date, "MM-DD-YYYY") : null
	startDate = startDate ? moment(startDate, "MM-DD-YYYY") : null
	lastDate = lastDate ? moment(lastDate, "MM-DD-YYYY") : null

	let days_of_data

	if (!last_transmission && !first_data_date) {
		return 0
	}

	if (!first_data_date) {
		return 0
	}

	if (!startDate && !lastDate) {
		days_of_data = moment(last_transmission || new Date()).diff(moment(first_data_date), "days")

		return days_of_data && days_of_data > 0 ? days_of_data + 1 : days_of_data === 0 ? 1 : 0
	}

	const isLastTransmissionInRange =
		startDate && lastDate && last_transmission
			? last_transmission.isBetween(startDate, lastDate, undefined, "[]")
			: null
	const isFirstDataDateInRange =
		startDate && lastDate && first_data_date ? first_data_date.isBetween(startDate, lastDate, undefined, "[]") : null
	const isLastTransmissionAfterEndDate = last_transmission && lastDate ? last_transmission.isAfter(lastDate) : null
	const isFirstDataDateBeforeStartDate = first_data_date && startDate ? first_data_date.isBefore(startDate) : null

	if (isLastTransmissionInRange && isFirstDataDateInRange) {
		// If ‘Last Transmission Date’ AND ‘First Data Date’ are within the selected time range

		days_of_data = last_transmission.diff(first_data_date, "days")
	} else if (isLastTransmissionAfterEndDate && isFirstDataDateInRange) {
		// If ‘Last Transmission Date’ is after ‘End Date’, AND ‘First Data Date’ is within the selected time range:

		days_of_data = lastDate.diff(first_data_date, "days")
	} else if (isLastTransmissionInRange && isFirstDataDateBeforeStartDate) {
		// If ‘Last Transmission Date’ precedes ‘Start Date’, AND ‘First Data Date’:

		days_of_data = last_transmission.diff(startDate, "days")
	} else if (!isLastTransmissionInRange && !isFirstDataDateInRange) {
		// If ‘Last Transmission Date’ and ‘First Data Date’ are both outside the selected time range:

		days_of_data = lastDate.diff(startDate, "days")
	} else {
		return 0
	}

	const LAST_DATA_POINT = 1

	return days_of_data ? days_of_data + LAST_DATA_POINT : 1
}
